import React from "react";

import styled from 'styled-components';
import './BootstrapCustomization.scss';
import {Button, Container, Nav, Navbar} from 'react-bootstrap'
import {Link} from "gatsby";
import {navigate} from "gatsby-link";
import {DarkRow} from "./Rows";
import {RowClassesJustifyContentEvenly} from "./RowStyles";
import {WhiteLink} from "./WhiteLink.js"
import {WhiteAnchor} from "./WhiteAnchor";

const TransparentNavbar = styled(Navbar)`
  background-color: rgba(0, 0, 0, 0);
  margin-bottom: -71px;
  padding-left: 22px;
  padding-right: 22px;
  padding-top: 16px;
  z-index: 10;
`;

export function Layout({children, className}) {
  className = className || "navbar-dark"
  return (
    <>
      <TransparentNavbar className={className + " justify-content-between"}>
        <Link to="/" className="navbar-brand">HIDDEN SWITCH</Link>
        <Nav className="d-flex justify-content-end">
          <div className="d-none d-sm-flex">
            <Link to="/careers" className="nav-link">Careers</Link>
            <Link to="/about" className="nav-link">About</Link>
          </div>
          <Button onClick={() => navigate('/contact')}>Contact</Button>
        </Nav>
      </TransparentNavbar>
      <Container fluid className="p-0 m-0 g-0">
        {children}
      </Container>
      <DarkRow className={RowClassesJustifyContentEvenly + " d-flex d-sm-none"}>
        <WhiteLink to="/">Home</WhiteLink>
        <WhiteLink to="/careers">Careers</WhiteLink>
        <WhiteLink to="/about">About</WhiteLink>
        <WhiteAnchor href={"https://www.playspellsource.com"}>Spellsource</WhiteAnchor>
        <WhiteAnchor href={"https://monstermatch.hiddenswitch.com"}>Monster Match</WhiteAnchor>
        <WhiteAnchor href={"https://foundation.mozilla.org"}>Mozilla Foundation</WhiteAnchor>
      </DarkRow>
      <Navbar bg={"dark"} className="navbar-dark justify-content-between p-1">
        <div className="p-1">© 2021 Hidden Switch, Inc.</div>
        <Nav className="d-flex justify-content-end">
          <div className="d-none d-sm-flex" style={{color: "white"}}>
            <Nav.Link href={"https://www.playspellsource.com"}>Spellsource</Nav.Link>
            <Nav.Link href={"https://monstermatch.hiddenswitch.com"}>Monster Match</Nav.Link>
            <Nav.Link href={"https://foundation.mozilla.org"}>Mozilla Foundation</Nav.Link>
          </div>
        </Nav>
      </Navbar>
    </>
  )
}
