import styled from "styled-components";
import {Link} from "gatsby";

export const WhiteLink = styled(Link)`
  color: white;

  :hover {
    color: white
  }
`
