import styled from "styled-components";
import {Row} from "react-bootstrap";
import atariPalette from "./AtariPalette";

export const DarkOnLightColor = atariPalette.paletteLitColorShadow2.rgba;
export const DarkOnLightRow = styled(Row)`
  color: ${DarkOnLightColor};
  background-color: ${atariPalette.paletteSky1Color2.rgba};
`;
export const DarkOnLightHeader = styled.h3`
  color: ${DarkOnLightColor};
  background-color: ${atariPalette.paletteSky1Color2.rgba};
  padding: 2px;
`
export const DarkOnLightParagraph = styled.p`
  color: ${DarkOnLightColor};
  background-color: ${atariPalette.paletteSky1Color2.rgba};
  padding: 2px;
`
export const BrightRow = styled(Row)`
  color: white;
  padding-bottom: 32px;
  background-color: ${atariPalette.paletteSky1Color5.rgba};
`;

export const SkyRow4 = styled(Row)`
  color: white;
  background-color: ${atariPalette.paletteSky1Color4.rgba};
`;

export const SkyRow6 = styled(Row)`
  color: white;
  background-color: ${atariPalette.paletteSky1Color6.rgba};
`;
export const GreenRow = styled(Row)`
  background-color: ${atariPalette.paletteHillColorPrimary.rgba};
`

export const MediumRow = styled(Row)`
  background-color: ${atariPalette.dark1.rgba};
`
export const DarkBackgroundColor1 = atariPalette.dark1.rgba;
export const DarkBackgroundHeader1 = styled.h3`
  background-color: ${DarkBackgroundColor1};
  padding: 2px;
`
export const DarkBackgroundParagraph1 = styled.p`
  background-color: ${DarkBackgroundColor1};
  padding: 2px;
`

export const DarkBackgroundColor = atariPalette.dark2.rgba;
export const DarkBackgroundHeader = styled.h3`
  background-color: ${DarkBackgroundColor};
  padding: 2px;
`
export const DarkBackgroundParagraph = styled.p`
  background-color: ${DarkBackgroundColor};
  padding: 2px;
`
export const DarkRow = styled(Row)`
  min-height: auto;
  color: white;
  background-color: ${DarkBackgroundColor};
`;
